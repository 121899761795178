<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <ticket-detail v-if="isLoad" />
    </b-tab>
    <!--/ general tab -->

    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Messages') }}
          </span>
      </template>

      <ticket-messages v-if="isLoad" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Orders') }}
          </span>
      </template>

      <ticket-orders v-if="isLoad && ticketType === 0" />
    </b-tab>

  </b-tabs>
</template>

<script>
import TicketDetail from './TicketDetail.vue'
import TicketMessages from './TicketMessages.vue'
import TicketOrders from './TicketOrders.vue'

export default {
  components: {
    TicketDetail,
    TicketMessages,
    TicketOrders,
  },
  data() {
    return {
      isLoad: false,
      ticketType: 0,
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      this.$store.dispatch('ticketManagement/fetchTicket', { ticketNo: paramId })
        .then(response => {
          this.isLoad = true;
          this.ticketType = this.response.result.customerTicket.ticketType;
        });
    },
  },
  created() {
    this.fetchItem();
  },
}
</script>
