<template>
  <div>
      <b-col cols="12" md="12" lg="12">
        <b-card v-for="item in getTicketDetails" :key="item.id">
          <div class="profile-twitter-feed">
            <div class="d-flex justify-content-start align-items-center mb-1">
              <b-avatar
                size="40"
                :src="'e'"
                class="mr-1"
              />
              <div class="profile-user-info">
                <h6 class="mb-0 text-success" :class="{ 'text-warning': item.customerName === null }">
                  {{ item.customerName === null ? item.userName : item.customerName}}
                </h6>
              </div>
              <b-link class="ml-auto">
                <small class="text-muted">{{ formatDateTime(item.createdDate) }}</small>
              </b-link>
            </div>
            <b-card-text class="mb-50"  v-html="item.message">
            </b-card-text>
            <a v-if="item.fileName !== null" :href="item.fileName" target="_blank">
              Open Uploaded File
            </a>
          </div>
        </b-card>
        <b-card>
          <b-form class="mt-1">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <quill-editor
                  v-model="messageData.message"
                  :options="snowOption"
                />
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <div class="mt-2">
                  <div class="file-upload btn-file-upload" v-if="messageData.file === null"
                      @click="openUpload()">
                    <feather-icon
                      icon="UploadIcon"
                      size="20"
                      class="text-primary"
                    />
                    Upload File
                  </div>
                  <div class="file-upload" v-else>
                    <feather-icon 
                      icon="Trash2Icon" size="18"
                      @click="removeUploadFile()"
                      class="text-danger btn-file-change-delete"/>
                    {{ this.messageData.file.name }}
                    <feather-icon
                      icon="CheckIcon"
                      size="20"
                      class="text-success"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <div class="demo-inline-spacing float-right">
                  <b-button
                    variant="outline-secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="$router.push({ name: 'ticket-all' })">
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      size="16"
                    />
                    {{ $t('Close') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disabled="$store.getters.getLoader"
                    type="submit"
                    @click.prevent="createMessage">
                    <b-spinner
                      v-if="$store.getters.getLoader"
                      class="mr-1"
                      small
                      variant="light"
                    />
                    <feather-icon
                      v-if="!$store.getters.getLoader"
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    {{ $t('Send') }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { formatDateTime } from '@core/utils/filter'

// Alert
import AlertService from '@/common/alert.service'
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      messageData: {
        message: null,
        file: null,
        ticketId: null,
      },
    }
  },
  methods: {
    formatDateTime,
    fetchTicketDetails() {
      const paramId = this.$router.currentRoute.params.id;
      this.$store.dispatch('ticketManagement/fetchTicket', { ticketNo: paramId });
    },

    createMessage() {
      if (this.messageData.message === null) {
        AlertService.warning(this, 'Write a message');
        return;
      }
      const formData = new FormData();
      formData.set('ticketId', this.getTicket.id);
      formData.set('message', this.messageData.message);
      formData.set('file', this.messageData.file);

      this.$store.dispatch('ticketManagement/createTicketDetail', formData)
        .then(response => {
          if (response.statusCode === 200) {
            this.messageData.message = null;
            this.messageData.file = null;
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
            this.fetchTicketDetails();
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        /* eslint-disable*/
        const file = event.target.files[0];
        const fileTypes = StaticOptions.ticketFileTypeOptions;
        if (fileTypes.indexOf(file.type) > -1) {
          this.messageData.file = file;
        } else {
          AlertService.warning(this, 'Invalid file...! Accepted File Formats:(.jpg,.jpeg,.png)');
        }
      })
      fileInput.click();
    },
    removeUploadFile() {
      this.messageData.file = null;
    },
  },
  computed: {
    getTicket() {
      const ticket = this.$store.getters['ticketManagement/getTicketData'];
      if (!ticket) {
        return null;
      }

      return ticket.customerTicket;
    },

    getTicketDetails() {
      const ticket = this.$store.getters['ticketManagement/getTicketData'];
      if (!ticket) {
        return null;
      }

      ticket.customerTicketDetails.forEach(item => {
        item.message = item.message.replace('\n', '<br>');
      });

      return ticket.customerTicketDetails;
    },
  },
}
</script>

<style scoped>
.file-upload {
  font-size: 15px;
  padding: 0.5rem;
}
.btn-file-upload {
  width: 130px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #00adb53b;
  transition: all 0.05s linear;
}

.btn-file-upload:hover {
  transform: rotate(0deg) scale(1.03, 1.03);
}

.btn-file-change-delete {
  cursor: pointer;
  border-radius: 5px;
}

.btn-file-change-delete:hover {
  background-color: #ea54554f !important;
}
</style>
