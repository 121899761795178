<template>
  <div>
      <b-card
        class="card-transaction"
        no-body
        v-if="getTicket !== null"
      >
        <b-card-header>
          <b-card-title>Summary</b-card-title>
        </b-card-header>
          <b-card-body>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="40"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="20"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">
                  {{ formatDateTime(getTicket.createdDate) }}
                </h6>
                <small>Order Date</small>
              </b-media-body>
              </b-media>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="40"
                  >
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="20"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ getTicket.ticketNo }}
                  </h6>
                  <small>Issue Number</small>
                </b-media-body>
              </b-media>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="40"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="20"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ getTicket.title }}
                  </h6>
                  <small>Title</small>
                </b-media-body>
              </b-media>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="40"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="20"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ getTicket.email }}
                  </h6>
                  <small>Email</small>
                </b-media-body>
              </b-media>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="40"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="20"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ getTicket.nameSureName }}
                  </h6>
                  <small>Full Name</small>
                </b-media-body>
              </b-media>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="40"
                  >
                    <feather-icon
                      icon="UsersIcon"
                      size="20"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ getTicket.personel === null ? '-' : getTicket.personel }}
                  </h6>
                  <small>Representative</small>
                </b-media-body>
              </b-media>
            </div>
          </b-card-body>
      </b-card>
      <b-card>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="$store.getters.getLoader"
          @click="closeTicket()">
          <b-spinner
            v-if="$store.getters.getLoader"
            class="mr-1"
            small
            variant="light"
          />
          {{ $t('Close Ticket') }}
        </b-button>
      </b-card>
  </div>
</template>

<script>
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'
import Ripple from 'vue-ripple-directive'
import { formatDateTime } from '@core/utils/filter'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    formatDateTime,
    closeTicket() {
      const paramId = this.$router.currentRoute.params.id;
      this.$store.dispatch('ticketManagement/closeTicket', { ticketNo: paramId })
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
            this.$router.push({ name: 'manage-ticket-open' });
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  computed: {
    getTicket() {
      const ticket = this.$store.getters['ticketManagement/getTicketData'];
      if (!ticket) {
        return null;
      }

      return ticket.customerTicket;
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
