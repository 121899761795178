<template>
  <div>
      <b-card
        class="card-transaction p-1"
        no-body
        v-if="ticketData !== null"
      >
        <b-table
          ref="refOrderListTable"
          class="position-relative"
          :items="orderList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          small
          empty-text="No matching records found"
        >

          <!-- Column: Date -->
          <template #cell(orderDate)="data">
            {{ formatDateTime(data.item.orderDate) }}
          </template>

          <!-- Column: user -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <small class="text-muted">@{{ data.item.user.fullName }} ({{ data.item.user.userName }})</small>
            </b-media>
          </template>

          <!-- Column: Status -->
          <template #cell(orderStatus)="data">
            <div class="text-nowrap">
              <b-badge
                pill
                :variant="resolveStatusVariant(data.item.orderStatus)"
              >
                <feather-icon
                  :icon="resolveStatusIcon(data.item.orderStatus)"
                  size="18"
                  class="mr-50 text-white"
                />
                <span class="align-text-top text-capitalize">{{ data.item.orderStatusLabel }}</span>
              </b-badge>
            </div>
          </template>

          <template #cell(subtotal)="data">
            {{ data.item.currencyUnit }}{{ data.item.subtotal }}
          </template>

          <template #cell(isDownloadedFiles)="data">
            <div class="text-nowrap">
              <b-form-checkbox
                :checked="data.item.isDownloadedFiles"
                class="custom-control-success"
                name="check-button"
                disabled
                >
                {{ $t('Downloaded') }}
              </b-form-checkbox>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <!-- Update -->
            <b-link :to="{ name: 'manage-order-save', params: { id: data.item.id }  }">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer text-primary"
                size="16"
              />
            </b-link>

            <!-- Remove -->
            <feather-icon
              @click="deleteOrder(data.item.id)"
              icon="Trash2Icon"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />

            <feather-icon
              @click="openCustomerResult(data.item)"
              icon="UserIcon"
              class="cursor-pointer mx-1 text-info"
              size="16"
            />
          </template>

        </b-table>
      </b-card>
  </div>
</template>

<script>
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import AlertService from '@/common/alert.service'
import Ripple from 'vue-ripple-directive'
import { avatarText, formatDateTime } from '@core/utils/filter'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      formatDateTime,
      orderList: [],
      tableColumns: [
        { key: 'orderNo', sortable: true },
        { key: 'orderDate', sortable: true },
        { key: 'user', sortable: true },
        { key: 'orderStatus', sortable: true },
        { key: 'orderTitle', sortable: true },
        { key: 'paymentTypeLabel', sortable: true },
        { key: 'subtotal', sortable: true },
        { label: 'DriveUpload', key: 'isDownloadedFiles' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    deleteOrder(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('orderManagement/removeOrder', { id })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: this.$t(response.message) },
              })
              this.fetchOrders()
            } else {
              AlertService.error(this, this.$t(response.message))
            }
          });
      });
    },
    openCustomerResult(item) {
      const url = `http://lab.illustrativedna.com/loginguid/${item.user.guid}`;
      const newwindow = window.open(url, 'User page', 'height=800,width=1100');
      if (window.focus) {
        newwindow.focus()
      }
    },

    fetchOrders() {
      const orderParams = {
        userId: this.ticketData.userId,
        productTypes: [0, 1, 2],
      };

      this.$store.dispatch('orderManagement/fetchOrders', orderParams)
        .then(response => {
          if (response) {
            const { data, count } = response.result;
            this.orderList = data;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    resolveStatusVariant(role) {
      if (role === 0) return 'danger'
      if (role === 1) return 'success'
      if (role === 2) return 'info'
      if (role === 3) return 'warning'
      return 'primary'
    },
    resolveStatusIcon(role) {
      if (role === 0) return 'SettingsIcon'
      if (role === 1) return 'UserIcon'
      if (role === 2) return 'Edit2Icon'
      if (role === 3) return 'ServerIcon'
      return 'UserIcon';
    },
  },
  computed: {
    ticketData() {
      const ticket = this.$store.getters['ticketManagement/getTicketData'];
      if (!ticket) {
        return null;
      }

      return ticket.customerTicket;
    },
  },
  mounted() {
    this.fetchOrders();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
